// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/utils/formatter.ts"
);
import.meta.hot.lastModified = "1732280811000";
}
// REMIX HMR END

export const formatTime = (time: number) => {
  const minutes = Math.floor(time / 60);
  const seconds = time % 60;
  return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
    2,
    "0"
  )}`;
};

export const formatDate = (time: string) => {
  const date = new Date(time);
  return `${date.toLocaleTimeString("fa-IR", {
    hour: "2-digit",
    minute: "2-digit",
  })} ${date.toLocaleDateString("fa-IR", {
    day: "numeric",
    month: "numeric",
    year: "numeric",
  })}`;
};

export const formatMBtoGB = (val: number | string, d: number = 2): string => {
  if (+val < 1000) {
    return val + " MB";
  }
  const gb = +val / 1000;
  if (gb >= 1000) {
    const tb = gb / 1000;
    return tb.toFixed(d) + " TB";
  } else {
    return gb.toFixed(d) + " GB";
  }
};

export const formatPrice = (value: number) => {
  const formattedValue =
    value > 1000000
      ? `${Math.floor(value / 1000000)} میلیون ${
          value % 1000000 && value % 1000000 !== 0
            ? "و " + (value % 1000000) / 1000 + " هزار"
            : ""
        } تومان`
      : value > 1000
      ? value / 1000 + " هزار تومان"
      : value + "تومان";
  return formattedValue;
};

export const persianMonth = [
  'فروردین',
  'اردیبهشت',
  'خرداد',
  'تیر',
  'مرداد',
  'شهریور',
  'مهر',
  'آبان',
  'آذر',
  'دی',
  'بهمن',
  'اسفند',
]
